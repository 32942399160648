import React, {
  TouchEvent,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  forwardRef
} from "react";

type Props = {
  id?: string;
  children?: ReactNode;
  className?: string;
  key?: string | number;
  element?: string;
  disabled?: boolean;
  tabIndex?: number;
  color?: string;
  variant?: string;
  type?: "button" | "submit" | "reset";
  "aria-label"?: string;
  "aria-pressed"?: boolean;
  "tracking-category"?: string;
  "tracking-action"?: string;
  "tracking-label"?: string;
  "tracking-a1"?: string;
  "tracking-a2"?: string;
  "aria-controls"?: string;
  "aria-roledescription"?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement> | boolean) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onMouseOver?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseOut?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: MouseEvent<HTMLButtonElement>) => void;
  onTouchStart?: (e: TouchEvent<HTMLButtonElement>) => void;
  onTouchEnd?: (e: TouchEvent<HTMLButtonElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLButtonElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: MouseEvent<HTMLButtonElement>) => void;
  role?: string;
  title?: string;
  preventDefaultMouseDown?: boolean;
  style?: Record<string, string | number>;
};

const CustomButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { preventDefaultMouseDown = true, ...rest } = props;

  return (
    <button
      {...rest}
      ref={ref}
      onMouseDown={e => preventDefaultMouseDown && e.preventDefault()}
    />
  );
});

CustomButton.displayName = "customButton";
export default CustomButton;
