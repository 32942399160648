// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#getimage
import { getImage } from "gatsby-plugin-image";

export default function createGatsbyImageMap(
  images: { edges?: { node?: any }[] },
  extractField?: string
): any {
  const imageMap = {};
  images.edges &&
    images.edges.forEach(function({ node }) {
      if (node.id && node["childImageSharp"]) {
        imageMap[node.name] = getImage(node);
      } else if (extractField && node[extractField]) {
        imageMap[node.name] = node[extractField];
      }
    });
  return imageMap;
}
