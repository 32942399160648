import React, { FC } from "react";

import Modal from "react-modal";

import * as styles from "./styles.module.scss";

import CustomButton from "~components/common/customButton";
import AiWriterTagWithToast from "~components/modalLowres/mobileEditSectionModal/aiWriterTagWithToast";
import { useThemeContext, THEME_NAME } from "~context/ThemeContext";
import { checkLocatedInEurope } from "~lib/dateFunctions";
import { getCurrentPageThemeColor } from "~lib/getThemeNameAndColor";

type Props = {
  id?: string;
  isOpen: boolean;
  btnConfirm?: boolean | JSX.Element;
  btnCancel?: boolean | JSX.Element;
  btnConfirmText?: string;
  btnCancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onRequestClose?: () => void;
  title?: string;
  isAIWriter?: boolean;
  onAIWriterBtnClick?: () => void;
  children?: JSX.Element | JSX.Element[];
  modalClass?: string;
  modalWrapper?: string;
  modalOverlay?: string;
  disabled?: boolean;
  middleTitle?: string | JSX.Element;
  imgContent?: string;
  previewHeader?: string;
  tappedImgView?: string;
  ariaLabel?: string;
  btnTabIndex?: number;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  modalContentClick?: () => void;
  modalTitleClass?: string;
  shouldReturnFocusAfterClose?: boolean;
  isCoverLetter?: boolean;
  role?: string;
};

const GeneralEditModal: FC<Props> = props => {
  const {
    id,
    isOpen,
    onRequestClose,
    onConfirm,
    onCancel,
    title,
    isAIWriter,
    onAIWriterBtnClick,
    children,
    modalClass,
    modalWrapper,
    btnConfirm = true,
    btnCancel = true,
    btnConfirmText = "Save",
    btnCancelText = "Dismiss",
    disabled,
    middleTitle,
    imgContent,
    previewHeader,
    tappedImgView,
    ariaLabel,
    btnTabIndex,
    onAfterOpen,
    onAfterClose,
    modalContentClick,
    modalTitleClass,
    shouldReturnFocusAfterClose = true,
    modalOverlay,
    isCoverLetter = null,
    role = "dialog"
  } = props;

  const theme = useThemeContext();
  const isRescomTheme = theme.mode === THEME_NAME.RESCOM;

  const currentThemeColor = getCurrentPageThemeColor(
    isCoverLetter,
    isRescomTheme
  );

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={`${modalWrapper || styles.modalWrapper}
      ${styles[modalClass] || modalClass}`}
      overlayClassName={`${modalOverlay || styles.modalOverlay}`}
      aria={{ label: ariaLabel }}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      role={role}
    >
      <div element="modalContainer" className={`${styles.modalContainer}`}>
        {(btnConfirm || btnCancel) && (
          <div
            element="modalHeader"
            className={`${styles.modalHeader} ${styles[previewHeader]}`}
          >
            <div className={styles.modalHeaderContainer}>
              <div className={styles.btnCancelContainer}>
                {btnCancel && typeof btnCancel === "object"
                  ? btnCancel
                  : btnCancel && (
                      <CustomButton
                        element="btn_cancel"
                        className={`${styles.btnCancel} ${
                          currentThemeColor ? `${currentThemeColor}-font` : ""
                        }`}
                        onClick={onCancel}
                        aria-label={btnCancelText}
                        tabIndex={btnTabIndex}
                      >
                        {btnCancelText}
                      </CustomButton>
                    )}
              </div>
              <div element="middleTitle" className={styles.middleTitle}>
                {middleTitle}
              </div>
              <div className={styles.btnConfirmContainer}>
                {btnConfirm && typeof btnConfirm === "object"
                  ? btnConfirm
                  : btnConfirm && (
                      <CustomButton
                        element="btn_confirm"
                        className={`${styles.btnConfirm} ${
                          currentThemeColor ? `${currentThemeColor}-font` : ""
                        }`}
                        onClick={onConfirm}
                        disabled={disabled}
                        aria-label={btnConfirmText}
                        tabIndex={btnTabIndex}
                      >
                        {btnConfirmText}
                      </CustomButton>
                    )}
              </div>
            </div>
          </div>
        )}
        {title && (
          <div
            className={`${styles.modalTitle} ${modalTitleClass} ${
              isAIWriter ? styles.modalTitleOfAIWriter : ""
            }`}
          >
            {title}
            {!checkLocatedInEurope() && isAIWriter && (
              <AiWriterTagWithToast onAIWriterBtnClick={onAIWriterBtnClick} />
            )}
          </div>
        )}
        <div
          element="modalContent"
          className={`${styles.modalContent} ${modalClass} ${styles[imgContent]} ${styles[tappedImgView]}`}
          role="none"
          onClick={() => modalContentClick && modalContentClick()}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
};

Modal.setAppElement("#___gatsby");

export default GeneralEditModal;
