// For creating resume through uploading resume from sidebar
export const UPLOAD_RESUME_FROM_SIDEBAR = "uploadResumeFromSidebar";

export const NOT_SHOW_DOWNLOAD_APP_MODAL = "notShowDownloadAppModal";

export const UPLOAD_SUCCESS = "uploadSuccess";

export const NEED_REDIRECT_TO_RESUME_BUILDER = "needRedirectToResumeBuilder";

export const EXPORT_CURRENT_RESUME = "exportCurrentResume";
export const LINK_CURRENT_RESUME = "linkCurrentResume";
export const LINK_CURRENT_RESUME_ON_MOBILE = "linkCurrentResumeOnMobile";

export const SHOW_HOMEPAGE_BANNER = "showHomepageBanner";
export const SHOW_ANNOUNCE_BANNER = "showAnnounceBanner";

export const HIDE_REBRANDING_WITH_INDEED_MESSAGE =
  "hideRebrandingWithIndeedMessage";
