import { navigate } from "gatsby";
import isEmpty from "lodash/isEmpty";

export const MOBILE_SCHEMA = "resume://";
export const IS_MOBILE_LOCAL_STORAGE = "IS_MOBILE_LOCAL_STORAGE";

export const openMobileLink = (pathWithoutSlash: string): void => {
  let mobileLink = `${MOBILE_SCHEMA}${pathWithoutSlash}`; // default is resume://path/to
  if (isAndroid()) {
    mobileLink = `intent://${pathWithoutSlash}#Intent;scheme=resume;end`;
  }
  window.location.href = mobileLink;
};

/**
 * Check if we have localStorage.getItem("IS_MOBILE_LOCAL_STORAGE")
 * @returns boolean
 */
export const isMobileSession = (): boolean => {
  const isMobile = localStorage.getItem(IS_MOBILE_LOCAL_STORAGE);
  return !isEmpty(isMobile);
};

export const removeMobileSession = (): void =>
  localStorage.removeItem(IS_MOBILE_LOCAL_STORAGE);

export const setMobileSession = (mobile?: string): void =>
  localStorage.setItem(IS_MOBILE_LOCAL_STORAGE, mobile || "isMobile");

/**
 * Open mobile linking or just run webNavigator if it's present
 * @param {*} path e.g /usertoken/xxxxx
 * @param {*} webNavigator e.g () => { navigate('/usertoken/xxxxx') }
 * @returns
 */
export const openMobileOrWeb = (path: string, webNavigator = null): void => {
  if (isMobileSession()) {
    const pathWithoutSlash = path.startsWith("/") ? path.substring(1) : path;
    removeMobileSession();
    return openMobileLink(pathWithoutSlash);
  }
  if (webNavigator) {
    return webNavigator(); // call navigator function
  }
  // normal navigate to path
  navigate(path);
};

/**
 * Check if we are in android browser using navigator.userAgent
 * @returns true or false
 */
export const isAndroid = (): boolean => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("android") > -1;
};

export default openMobileOrWeb;
